import { Image } from "./image";
import React from "react";

import { Navigation } from "./navigation";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


toast("Bem vindo a Fortesol! Aqui você encontra soluções em engenharia elétrica. Conheça nossos serviços e solicite um orçamento.", {
  position: "top-center",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});
export const Gallery = (props) => {

  return (
    <>
    <ToastContainer />
   
    <div id="portfolio" className="text-center">
      
      
      <div className="container">

        <div className="section-title">
          <h2>Conheça nossos serviços</h2>

        </div>
         
   <MdKeyboardArrowDown id="arrow"
                 style={{
                 
                 
                   transform: "translate(-50%, -50%)",
                   fontSize: "5em",
                 }}
               />
        <div className="row ">
          <div
            className=" portfolio-item">
            {props.data
              ? props.data.map((d, i) => (
                <div

                  key={`${d.title}-${i}`}
                  className="col-lg-5 "
                >  <a target="_blank" href={d.link}>
                 
                    <Image
                      title={d.title}

                      smallImage={d.smallImage}
                    />
                    <strong><h3>{d.subTitle}</h3></strong>
                    <h5 style={{ textAlign: 'center', }}>{d.description}</h5>
                    <a
                      href={d.link}
                      target="_blank"
                      className="btn btn-custom  btn-lg page-scroll"
                    >
                      Clique aqui 
                    </a>{" "}
                    

                  </a>
                </div>
              ))
              : "Loading..."}
          </div>
        </div>
      </div>
 
    </div>
    </>
  );
};
