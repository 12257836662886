import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";


import { Gallery } from "./components/gallery";

import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
  

  
      <Gallery data={landingPageData.Gallery} />



      <a
      target="_blank"
        href="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
        className="btn btn-custom-wapp  btn-lg page-scroll"
        style={{
          position: "fixed",
          top: "95%",
          left: "88%",
          transform: "translate(-50%, -50%)",
        }}
        
      >
        
        ATENDIMENTO WHATSAPP <i className="fa fa-whatsapp"></i>
      </a>{" "}
      <a
      target="_blank"
        href="https://api.whatsapp.com/send?phone=55854141-4900&text=Olá, gostaria de mais informações sobre os serviços da Fortesol"
        className="btn btn-wapp  btn-lg page-scroll"
        style={{
          position: "fixed",
          top: "90%",
          left: "88%",
          transform: "translate(-50%, -50%)",
          display: "none",

        }}
      >
        <i className="fa fa-whatsapp"></i>
      </a>{" "}
    </div>
  );
};

export default App;
